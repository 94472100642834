<template>
  <div class="finance-account">
    <en-table-layout :tableData="tableData.data" :loading="loading">
      <template slot="toolbar">
        <div class="col toolbar-title">
          导出订单记录
          <el-button style="float: right;" @click="() => { $router.go(-1); }" size="small" class="back_btn">
            <img src="@/assets/back.png" alt="">
          </el-button>
        </div>
        <div style="width: 100%;margin-top:10px">
          <el-form-item label="导出时间">
            <el-date-picker style="width: 220px" v-model="advancedForm.order_time_range" type="daterange" align="center"
              size="medium" :editable="false" unlink-panels range-separator="-" start-placeholder="开始日期"
              end-placeholder="结束日期"></el-date-picker>
          </el-form-item>
          <el-form-item label="操作人">
            <el-input clearable v-model="params.operator" placeholder="请输入操作人登录账号"></el-input>
          </el-form-item>
          <el-form-item class="col-auto">
            <el-button @click="searchEvent" type="primary" size="small">搜索</el-button>
          </el-form-item>
        </div>
      </template>

      <template slot="table-columns">
        <el-table-column label="导出时间" align="center">
          <template slot-scope="{row}">{{ row.create_time | unixToDate }}</template>
        </el-table-column>
        <el-table-column label="操作人" align="center">
          <template slot-scope="{row}">{{ row.operator }}</template>
        </el-table-column>
        <el-table-column label="操作" width="300" align="center">
          <template slot-scope="{row}">
            <el-button size="mini" type="primary" @click="download(row)">下载导出文件</el-button>
          </template>
        </el-table-column>
      </template>
      <el-pagination slot="pagination" @size-change="handlePageSizeChange" @current-change="handlePageCurrentChange"
        :current-page="tableData.page_no" :page-sizes="MixinPageSizes" :layout="MixinTableLayout" background
        :page-size="tableData.page_size" :total="tableData.data_total"></el-pagination>
    </en-table-layout>
  </div>
</template>

<script>
import { getExportOrderList, tradeExportInfo } from '@/api/order';
import EnTableLayout from '../../../../ui-components/TableLayout/src/main';
import { handleDownload, mergeSkuList } from "@/utils";
import { Foundation } from "@/../ui-utils";
export default {
  name: 'exportOrderRecords',
  components: { EnTableLayout },
  data () {
    return {
      advancedForm: {
        order_time_range: [],
      },
      /** 列表loading状态 */
      loading: false,
      /** 列表参数 */
      params: {
        page_no: 1,
        page_size: 20
      },
      /** 列表数据 */
      tableData: {},
      /** 店铺信息 */
      shopInfo: this.$store.getters.shopInfo,
    };
  },
  mounted () {
    this.GET_AccountList();
  },
  methods: {
    /** 搜索事件触发 */
    searchEvent () {
      this.params.page_no = 1;
      this.GET_AccountList();
    },
    /** 分页大小发生改变 */
    handlePageSizeChange (size) {
      this.params.page_size = size;
      this.GET_AccountList();
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange (page) {
      this.params.page_no = page;
      this.GET_AccountList();
    },
    GET_AccountList () {
      this.loading = true;

      if (this.advancedForm.order_time_range && this.advancedForm.order_time_range.length) {

        if (typeof this.advancedForm.order_time_range[0] === "string") {
          this.params.start_time =
            new Date(this.advancedForm.order_time_range[0]).getTime() / 1000;
          this.params.end_time =
            new Date(this.advancedForm.order_time_range[1]).getTime() / 1000 +
            86400;
        } else {
          this.params.start_time =
            this.advancedForm.order_time_range[0].getTime() / 1000;
          this.params.end_time =
            this.advancedForm.order_time_range[1].getTime() / 1000 + 86400;
        }
      } else {
        delete this.params.start_create_time
        delete this.params.end_create_time
      }
      let params = this.params;
      getExportOrderList(params).then(response => {
        this.loading = false;
        this.tableData = response;
      });
    },
    //下载导出文件
    download (row) {
      this.importLoading = true;
      let tHeaders = [];
      let filterVals = [];
      if (this.parentLogin === 1) {
        tHeaders = [
          "订单编号",
          "商品名称",
          "商品单价",
          "分销价",
          // "销售价",
          "会员价",
          "商品数量",
          "售后状态",
          "物流状态",
          "物流单号",
          "供货方",
          "订单状态",
          "下单时间",
          "下单账号",
          "收件人",
          "收件电话",
          "收货地址"
        ];
        filterVals = [
          "trade_sn",
          "goods_name",
          "shop_price",
          "enterprise_price",
          // "shop_price",
          "original_price",
          "goods_num",
          "service_status",
          "state",
          "deliveryNo",
          "order_source",
          "trade_status_text",
          "create_time",
          "member_name",
          "consignee_name",
          "consignee_mobile",
          "address"
        ];
      } else {
        tHeaders = [
          "订单编号",
          "商品名称",
          "商品单价",
          "分销价",
          // "销售价",
          "商品数量",
          "售后状态",
          "物流状态",
          "物流单号",
          "供货方",
          "订单状态",
          "下单时间",
          "下单账号",
          "收件人",
          "收件电话",
          "收货地址"
        ];
        filterVals = [
          "trade_sn",
          "goods_name",
          "shop_price",
          "enterprise_price",
          // "shop_price",
          "goods_num",
          "service_status",
          "state",
          "deliveryNo",
          "order_source",
          "trade_status_text",
          "create_time",
          "member_name",
          "consignee_name",
          "consignee_mobile",
          "address"
        ];
      }

      let params = { ...this.params };

      params.page_no = 0;
      params.page_size = 0;
      tradeExportInfo({ id: row.id }).then(res => {
        let data = mergeSkuList(res, 1);
        data.forEach(item => {
          item.goods_name = item.goods_name.replaceAll('amp;', '').replaceAll(' ', '&nbsp;')
          item.service_status = this.MixinGetOrderStatus(item.service_status);
          item.state =
            item.state === 0
              ? "未发货"
              : item.state === 2
                ? "已发货"
                : "已收货";
          item.order_source =
            item.order_source === 0
              ? "平台"
              : item.order_source === 1
                ? "京东"
                : "自有";
          item.create_time = Foundation.unixToDate(item.create_time);
          item.deliveryNo = item.delivery && item.delivery.deliveryNo;
          item["address"] =
            item.consignee_province +
            item.consignee_city +
            item.consignee_county +
            item.consignee_town +
            item.consignee_address;
          item.platform_price = item.order_source === '自有' ? '---' : item.platform_price;
        });
        handleDownload(data, tHeaders, filterVals, "订单列表");
      })
    }
  }
};
</script>

<style lang="scss" scoped>
.finance-account-table-header {
  padding: 16px 16px 0;

  .line {
    font-size: 13px;
    color: #666;
  }
}

/deep/ .el-radio-group {
  .el-radio {
    margin-bottom: 0 !important;
  }
}

.en-table-layout .en-table-layout-toolbar .el-form-item {
  margin-bottom: 8px;
}

.back_btn {
  width: 36px;
  height: 36px;
  background-color: #1A43A9;
  border-radius: 50%;

  img {
    width: 10px;
    height: 20px;
    position: relative;
    top: -2px;
    left: -4px;
  }
}
</style>
